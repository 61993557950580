<template>
  <div>
    <navigation />
    <LazySectionsHomeHero />
    <LazySectionsHomePresentation />
    <LazySectionsHomeServicesHome />
    <LazySectionsHomeClientsHome />
    <LazySectionsHomeCaseStudiesHome />
    <LazySectionsGeneralBottomcaller />
    <LazySectionsGeneralFooterMain />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useHead } from '#imports'
import navigation from '../components/sections/general/navigation.vue'

const { t } = useI18n()

useHead({
  title: t('meta.home'),
  meta: [
        {hid: 'description', name: 'description', content: t('pages.home.hero.creative-services.description') },
        {hid: 'og:title', name: 'og:title', content: t('meta.home') },
        {hid: 'og:description', name: 'og:description', content: t('pages.home.hero.creative-services.description') },
        {hid: 'twitter:title', name: 'twitter:title', content: t('meta.home') },
        {hid: 'twitter:description', name: 'twitter:description', content: t('pages.home.hero.creative-services.description') },
        {hid: 'og:type', name: 'og:type', content: 'website'},
        {hid: 'og:url', name: 'og:url', content: 'https://www.rollin.org'},
        {hid: 'image', name: 'image', property: 'og:image', content: '/og-image.jpg'},
        {hid: 'og:image', name: 'og:image', content: '/og-image.jpg'},
        {hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image'},
        {hid: 'twitter:url', name: 'twitter:url', content: 'https://www.rollin.org'},
        {hid: 'twitter:image', name: 'twitter:image', content: '/og-image.jpg'},
{hid: 'og:logo', name: 'og:logo', content: '/logo.png'},
        {hid: 'twitter:domain', name: 'twitter:domain', content: 'rollin.org'}

  ],
})
</script>
